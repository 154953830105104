import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BGImg from 'gatsby-background-image'

import './index.css'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "home/kandidaten.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      melcher: file(relativePath: { eq: "home/melcher.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
  const scrollRef = React.useRef(null)
  const executeScroll = () => scrollToRef(scrollRef)

  return (
    <Layout>
      <SEO title='CSU Neutraubling' />
      <BGImg className='hero-wrapper' fluid={data.hero.childImageSharp.fluid}>
        {/* <div className='overlay'>
          <div className='hero-content'>
            <div className='btns d-none d-sm-block'>
              <Link to='/wahl2020'>
                <button className='btn btn-primary'>
                  #neutraubling2020 – Zu den Kandidaten
                </button>
              </Link>
            </div>
          </div>
        </div> */}
      </BGImg>
      {/* <div className='btns'>
        <Link to='/wahl2020'>
          <button className='btn btn-primary btn-block d-block d-md-none'>
            #neutraubling2020 – Zu den Kandidaten
          </button>
        </Link>
      </div> */}

      <div ref={scrollRef} className='my-5'>
        <div className='row mx-0'>
          <div className='col-md-4 mb-sm-5 mb-md-0'>
            <div className='card h-100'>
              <div className='card-body'>
                <h2 className='card-title'>
                  Herzlich Willkommen bei der CSU Neutraubling
                </h2>
                <div className='card-text'>
                  <p>
                    Die CSU Neutraubling gestaltet seit ihrer Gründung 1949 die
                    Entwicklung der Stadt Neutraubling wesentlich mit. Wir
                    stehen für Tradition und Moderne, für wirtschaftliche Stärke
                    und Nachhaltigkeit und vor allem für die Interessen der
                    Menschen vor Ort.
                  </p>
                  <p>
                    Haben Sie Anregungen und Ideen? Dann kontaktieren Sie uns
                    gerne direkt.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-8 mb-sm-5 mb-md-0'>
            <iframe
              src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCSUNeutraubling&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId'
              width='100%'
              height='500'
              style={{ border: 'none', overflow: 'hidden' }}
              scrolling='no'
              frameborder='0'
              allowfullscreen='true'
              allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>
          </div>
          {/* <div className='col-md-4 mb-sm-5 mb-md-0'>
            <div className='card h-100'>
              <div className='card-body'>
                <h2 className='card-title'>Kommunalwahlen</h2>
                <div className='card-text'>
                  <p>
                    Mit unseren 24 Kandidatinnen und Kandidaten, die mit ihrem
                    Alter, ihrer Lebenssituationen und Erfahrungen die
                    Vielseitigkeit unserer Stadt repräsentieren, haben wir eine
                    starke Liste zusammengestellt, die mit großer Überzeugung
                    für unsere Stadt Neutraubling eintritt. Zusammen mit Michael
                    Melcher als Bürgermeisterkandidat wollen wir, dass
                    Neutraubling weiter mit Herz und Verstand regiert wird.
                  </p>
                  <Link
                    to='/politik'
                    className='btn btn-outline-primary btn-block'>
                    Detailierte Informationen zu unseren Kandidaten
                  </Link>
                  <Link to='/politik' className='btn btn-primary btn-block'>
                    Unsere Ziele finden Sie hier.
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className='col-md-4 mb-sm-5 mb-md-0'>
            <div className='card h-100'>
              <div className='card-body'>
                <h2 className='card-title'>Termine</h2>
                <div className='card-text'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col'>Datum</th>
                        <th scope='col'>Ereignis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope='row'>12.01.2020</th>
                        <td>Neujahrsgespräch</td>
                      </tr>
                      <tr>
                        <th scope='row'>26.01.2020</th>
                        <td>Kinderfasching</td>
                      </tr>
                      <tr>
                        <th scope='row'>14.02.2020</th>
                        <td>Valentinstag</td>
                      </tr>
                      <tr>
                        <th scope='row'>15.02.2020</th>
                        <td>Ball der Union</td>
                      </tr>
                      <tr>
                        <th scope='row'>27.02.2020</th>
                        <td>Politischer Ascherdonnerstag</td>
                      </tr>
                      <tr>
                        <th scope='row'>15.03.2020</th>
                        <td>Kommunalwahl</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className='card text-center mb-5'>
        <BGImg
          className='michael-wrapper'
          fluid={data.melcher.childImageSharp.fluid}>
          <div className='card-body text-left'>
            <a
              href='https://www.michaelmelcher.de'
              className='btn btn-lg btn-primary'
              style={{ backgroundColor: '#a5c60d', borderColor: '#a5c60d' }}>
              Zur Webseite des Bürgermeisterkandidaten Michael&nbsp;Melcher
            </a>
          </div>
        </BGImg>
      </div> */}
    </Layout>
  )
}

export default IndexPage
